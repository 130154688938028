import { isEnabledFeature } from "../featureToggle";

export type ApplicationConfiguration = {
  id: string;
  label: string;
  name: string;
  categoryId?: string;
  route: {
    path: string;
    order: number;
    exact: boolean;
  };
  active?: boolean;
  redirectsRoutePaths?: string[];
};

const applicationConfigurations: ApplicationConfiguration[] = [
  {
    id: "discovery-profile",
    label: "Discover People",
    name: "@one-profile/discovery-profile",
    route: {
      path: "/discover",
      order: 100,
      exact: false,
    },
  },
  {
    id: "my-profile",
    label: "My Profile",
    name: "@one-profile/social-profile",
    route: {
      path: "/profile",
      order: 50,
      exact: false,
    },
  },
  {
    id: "hardware",
    label: "Hardware",
    name: "@one-profile/hardware-utility-profile",
    categoryId: "join-work-grow",
    route: {
      path: "/hardware",
      order: 200,
      exact: false,
    },
  },
  {
    id: "software",
    label: "Software",
    name: "@mytw/software-assets-utitlity-frontend",
    categoryId: "join-work-grow",
    route: {
      path: "/software",
      order: 300,
      exact: false,
    },
  },
  {
    id: "grow",
    label: "Learning",
    name: "@one-profile/grow",
    categoryId: "join-work-grow",
    route: {
      path: "/learning",
      order: 500,
      exact: false,
    },
  },
  {
    id: "self-service-letters",
    label: "Letters",
    name: "@one-profile/self-service-letters",
    categoryId: "employment",
    route: {
      path: "/letters",
      order: 600,
      exact: false,
    },
  },
  {
    id: "iContribute",
    label: "iContribute",
    name: "@mytw/iContribute",
    categoryId: "join-work-grow",
    route: {
      path: "/iContribute",
      order: 700,
      exact: false,
    },
  },
  {
    id: "account",
    label: "Account",
    name: "@mytw/account-frontend",
    categoryId: "leadership",
    route: {
      path: "/account",
      order: 60,
      exact: false,
    },
  },
];

const officev2Application = {
  id: "office",
  label: "Office",
  name: "@mytw/office-frontend",
  categoryId: "join-work-grow",
  route: {
    path: "/office",
    order: 400,
    exact: false,
  },
  redirectsRoutePaths: ["/id-card"],
};

export function getEnabledApplicationConfigurations() {
  if (isEnabledFeature("office_v2_page")) {
    return [...applicationConfigurations, officev2Application];
  }
  return [...applicationConfigurations];
}

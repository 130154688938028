import { RegisterApplicationConfig } from "../../application/constructApplications";
import { Office, Profile } from "@one-profile/core";
import { resolveActivation } from "../../application/applicationConfigurations/resolveActivation";
import { applicationStore } from "../../store/applicationStore";
import { applicationConfigSlice } from "../../store/applicationStore/applicationConfiguration";
import { getApplicationConfigurationBy } from "../../application/applicationConfigurations";
import { registerApplication } from "single-spa";

export function registerActivatedApplication(
  application: RegisterApplicationConfig,
  user: Profile & Office
) {
  return resolveActivation(application, user).then((isActive) => {
    if (isActive) {
      application.customProps["user"] = user;
      applicationStore.dispatch(
        applicationConfigSlice.actions.addActiveApplication(
          getApplicationConfigurationBy(application)
        )
      );
      registerApplication(application);
    }
  });
}
